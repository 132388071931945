import { extend } from 'vee-validate';
import {
  required, email, numeric, between, confirmed, min_value as minValue,
} from 'vee-validate/dist/rules';
import { isUrl } from './util';

// email validation rule
extend('email', email);

// custom required rule
extend('required', {
  ...required,
  message: '{_field_} is required',
});

// custom confirmed/match rule
extend('confirmed', {
  ...confirmed,
  message: '{target} does not match',
});

// custom numeric rule
extend('numeric', {
  ...numeric,
  message: '{_field_} can only consist of numbers',
});

// custom between value rule
extend('between', {
  ...between,
  message: '{_field_} must be between {min} and {max}',
});

// custom greater_than value rule
extend('minValue', {
  ...minValue,
  params: [{ name: 'min', isTarget: true }],
  message: '{_field_} must be equal to or more than {min}',
});

// custom required_ifnot rule
extend('required_ifnot', {
  ...required,
  params: ['targetValue', 'target'],
  validate(value, { targetValue }) {
    const meRqd = required.validate(value);
    const targetRqd = required.validate(targetValue);
    return meRqd.valid || targetRqd.valid;
  },
  message: 'Either {_field_} or {target} is required',
});

// custom isbn13 rule
extend('isbn13', {
  validate(value) {
    // ToDo: Add correct logic for ISBN13 validation
    return (value.length === 13);
  },
  message: '{_field_} is not a valid ISBN13. It should be like 9781402727177.',
});

// custom isbn10 rule
extend('isbn10', {
  validate(value) {
    // ToDo: Add correct logic for ISBN10 validation
    return (value.length === 10);
  },
  message: '{_field_} is not a valid ISBN10. It should be like 1402727178.',
});

extend('url', {
  validate(value) {
    return isUrl(value);
  },
  message: '{_field_} is not a valid Url. E.g https://example.com/images/my_image.jpg',
});
